#header {
  display: none;
}

body {
  font-family: YakuHanJP, Gothic Medium BBB, sans-serif;
}

h1, h2, h3 {
  font-family: YakuHanJP, Midashi Go MB31, sans-serif;
}

h3 {
  text-transform: inherit;
}

blockquote {
  padding: 0;
  font-size: 0.875em;
  font-style: normal;
}

button {
  opacity: 1;
  transition: opacity 500ms;
}

button:hover, button:active, button:focus {
  opacity: 0.8;
}

@media screen and (max-width: 640px) {
  .Footer .sqs-gallery-design-grid-slide {
    width: 33.3333% !important;
  }
}

.font__midashi-go-mb31-pr6n, #floatingfooter .container .memorialset__amount, #floatingfooter .container .memorialset__text, #floatingfooter .container .button__text--reserve, #modal .item__title, #modal .purchase__text {
  font-family: Midashi Go MB31, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.font__gothic-bbb-pr6n, #modal .item__price, #modal .item__tax, #modal .item__date {
  font-family: Gothic Medium BBB, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.font__futo-go-b101-pr6n {
  font-family: a-otf-futo-go-b101-pr6n, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.font--ten-mincho {
  font-family: ten-mincho, serif;
  font-style: normal;
  font-weight: 400;
}

.font--eb-garamond {
  font-family: eb-garamond, serif;
  font-style: normal;
  font-weight: 400;
}

* {
  box-sizing: border-box;
  font-feature-settings: "palt";
}

@media screen and (max-width: 735px) {
  #footer .sqs-gallery-design-grid-slide {
    width: 33.3333% !important;
  }
}

#atf .Index-page-scroll-indicator {
  display: none;
}

#atf .Index-page-content {
  max-width: 100vw;
  padding: 0;
}

#atf .sqs-layout > .sqs-row {
  margin: 0;
}

#atf .sqs-layout .sqs-row .sqs-block {
  padding: 0 !important;
}

#atf .atf {
  position: relative;
}

#atf .atf__sales-period {
  position: absolute;
  top: 0;
  left: 0;
  width: 15vw;
  z-index: 5;
}

@media screen and (max-width: 735px) {
  #atf .atf__sales-period {
    width: 29vw;
  }
}

#atf .atf__title-area {
  position: absolute;
  top: 33vh;
  right: 0;
  left: 0;
  width: 16vw;
  margin: 0 auto;
  z-index: 5;
}

@media screen and (max-width: 735px) {
  #atf .atf__title-area {
    top: 26vh;
    width: 30vw;
  }
}

#atf .slick-slider__item {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#atf .slick-slider__item--1 {
  background-image: url("https://common.lp.koyamachuya.com/heart-moon-stones.lp.koyamachuya.com/images/top-pc_01.jpg");
}

@media screen and (max-width: 735px) {
  #atf .slick-slider__item--1 {
    background-image: url("https://common.lp.koyamachuya.com/heart-moon-stones.lp.koyamachuya.com/images/top-sp_01.jpg");
  }
}

#atf .slick-slider__item--2 {
  background-image: url("https://common.lp.koyamachuya.com/heart-moon-stones.lp.koyamachuya.com/images/top-pc_02.jpg");
}

@media screen and (max-width: 735px) {
  #atf .slick-slider__item--2 {
    background-image: url("https://common.lp.koyamachuya.com/heart-moon-stones.lp.koyamachuya.com/images/top-sp_02.jpg");
  }
}

#atf .slick-slider__item--3 {
  background-image: url("https://common.lp.koyamachuya.com/heart-moon-stones.lp.koyamachuya.com/images/top-pc_03.jpg");
}

@media screen and (max-width: 735px) {
  #atf .slick-slider__item--3 {
    background-image: url("https://common.lp.koyamachuya.com/heart-moon-stones.lp.koyamachuya.com/images/top-sp_03.jpg");
  }
}

#point .Index-page-content {
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

#point .sqs-layout > .sqs-row {
  margin: 0;
}

#point .sqs-layout .sqs-row .sqs-block:last-child {
  padding: 0 !important;
}

#tobuy .purchase__button {
  padding: 0;
  border: none;
  background: transparent;
}

#floatingfooter {
  display: flex;
  justify-content: center;
  align-items: center;
}

#floatingfooter .Index-page-content {
  padding: 0;
}

#floatingfooter .sqs-layout > .sqs-row {
  margin: 0;
}

#floatingfooter .sqs-block {
  padding: 0 !important;
}

#floatingfooter.show {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}

#floatingfooter {
  width: 100vw;
  height: 4rem;
  height: constant(64pxenv(safe-area-inset-bottom));
  height: calc(64px + env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #F0ECEC;
  opacity: 0;
  z-index: 999;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

#floatingfooter * {
  line-height: 1;
}

#floatingfooter .page-content {
  padding: 0;
}

#floatingfooter .container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 23.4375rem;
  margin: 0 auto;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container {
    max-width: 20rem;
  }
}

#floatingfooter .container .memorialset {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #51A3D1;
}

#floatingfooter .container .memorialset__amount {
  margin: 0;
  font-size: 14px;
  font-size: 0.875rem;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .memorialset__amount {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

#floatingfooter .container .memorialset__text {
  margin: 0;
  margin-top: 0.40625rem;
  font-size: 10px;
  font-size: 0.625rem;
}

@media screen and (max-width: 735px) {
  #floatingfooter .container .memorialset__text {
    margin-top: 0.4375rem;
  }
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .memorialset__text {
    font-size: 10px;
    font-size: 0.625rem;
  }
}

#floatingfooter .container .button {
  display: flex;
  align-items: center;
  padding: 0.78125rem 1.4375rem;
  background-color: #51A3D1;
  border-radius: 0.15625rem;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .button {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

#floatingfooter .container .button__icon {
  width: 1.3125rem;
  height: 1.3125rem;
}

#floatingfooter .container .button__text {
  margin-left: 0.5rem;
  color: #ffffff;
}

#floatingfooter .container .button__text--reserve {
  margin: 0;
  font-size: 19px;
  font-size: 1.1875rem;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .button__text--reserve {
    font-size: 16px;
    font-size: 1rem;
  }
}

#modal .Index-page-content {
  padding: 0;
}

#modal .sqs-layout > .sqs-row {
  margin: 0;
}

#modal .sqs-block {
  padding: 0 !important;
}

#modal {
  line-height: 1;
}

#modal .page-content {
  padding: 0;
}

#modal .modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: translateY(100vh);
  transition: transform 10ms 300ms;
  z-index: 1000;
}

#modal .modal.open {
  transform: translateY(0);
  transition: transform 10ms;
}

#modal .modal.open .modal__backdrop {
  background-color: rgba(0, 0, 0, 0.53);
  transition: background-color 300ms 10ms;
}

#modal .modal.open .modal__content {
  transform: translateY(0);
  transition: transform 300ms;
}

#modal .modal__backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 300ms;
}

#modal .modal__content {
  width: 21.25rem;
  position: relative;
  transform: translateY(100vh);
  transition: transform 300ms;
  overflow: visible;
}

@media screen and (max-width: 374px) {
  #modal .modal__content {
    width: 18.75rem;
  }
}

#modal .modal__close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0.3125rem 0.4375rem;
  font-size: 19px;
  font-size: 1.1875rem;
  background-color: #e8e5e5;
  border-radius: 50%;
  transform: translate(0.5rem, -0.5625rem);
  cursor: pointer;
}

#modal .content {
  background-color: #f4f4f3;
  border-radius: 0.375rem;
}

#modal .content__inner {
  padding: 1.71875rem 1.53125rem 2.1875rem 1.6875rem;
}

@media screen and (max-width: 374px) {
  #modal .content__inner {
    padding: 1rem 0.9375rem 1.25rem 0.9375rem;
  }
}

@media screen and (max-width: 735px) {
  #modal .content__items {
    max-height: 58vh;
    overflow-y: scroll;
  }
}

#modal .item {
  padding-top: 1.4375rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid #dedede;
}

#modal .item__set {
  padding-top: 0;
}

#modal .item__info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

#modal .item__text {
  margin-right: 0.625rem;
}

#modal .item__title {
  margin: 0;
  margin-bottom: 0.4375rem;
  font-size: 0.875rem;
  color: #3e3a39;
}

#modal .item__price {
  margin: 0;
  font-size: 0.875rem;
  color: #3e3a39;
}

#modal .item__tax {
  margin-left: 0.3125rem;
  font-size: 0.625rem;
  color: #3e3a39;
}

#modal .item__image {
  width: 2.3125rem;
  height: 2.3125rem;
}

#modal .item__purchase {
  display: flex;
  justify-content: space-between;
}

#modal .item__date {
  margin: 0;
  margin-top: 0.625rem;
  font-size: 12px;
  font-size: 0.75rem;
  color: #3e3a39;
}

#modal .purchase__pierce {
  width: 47%;
}

#modal .purchase__arrings {
  width: 47%;
}

#modal .purchase__button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0;
  background: transparent;
  background-color: #51a3d1;
  border: none;
  border-radius: 0.125rem;
}

#modal .purchase__cart {
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.28125rem;
}

#modal .purchase__text {
  width: auto;
  margin: 0;
  color: #ffffff;
  font-size: 12px;
  font-size: 0.75rem;
}
