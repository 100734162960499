#point {
  .Index-page-content {
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-layout .sqs-row .sqs-block:last-child {
    padding: 0 !important;
  }
}