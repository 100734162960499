#atf {

  .Index-page-scroll-indicator {
    display: none;
  }

  .Index-page-content  {
    max-width: 100vw;
    padding: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-layout {
    .sqs-row {
      .sqs-block {
        padding: 0 !important;
      }
    }
  }

  .atf {
    position: relative;

    &__sales-period {
      position: absolute;
      top: 0;
      left: 0;
      width: 15vw;
      z-index: 5;

      @include mq() {
        width: 29vw;
      }
    }

    &__title-area {
      position: absolute;
      top: 33vh;
      right: 0;
      left: 0;
      width: 16vw;
      margin: 0 auto;
      z-index: 5;

      @include mq() {
        top: 26vh;
        width: 30vw;
      }
    }
  }

  .slick-slider {
    &__item {
      width: 100vw;
      height: 100vh;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &--1 {
        background-image: url('https://common.lp.koyamachuya.com/heart-moon-stones.lp.koyamachuya.com/images/top-pc_01.jpg');

        @include mq() {
          background-image: url('https://common.lp.koyamachuya.com/heart-moon-stones.lp.koyamachuya.com/images/top-sp_01.jpg');
        }
      }

      &--2 {
        background-image: url('https://common.lp.koyamachuya.com/heart-moon-stones.lp.koyamachuya.com/images/top-pc_02.jpg');

        @include mq() {
          background-image: url('https://common.lp.koyamachuya.com/heart-moon-stones.lp.koyamachuya.com/images/top-sp_02.jpg');
        }
      }

      &--3 {
        background-image: url('https://common.lp.koyamachuya.com/heart-moon-stones.lp.koyamachuya.com/images/top-pc_03.jpg');

        @include mq() {
          background-image: url('https://common.lp.koyamachuya.com/heart-moon-stones.lp.koyamachuya.com/images/top-sp_03.jpg');
        }
      }
    }
  }
}