.font {
  &__midashi-go-mb31-pr6n {
    font-family: Midashi Go MB31, sans-serif;
    font-style: normal;
    font-weight: 600;
  }

  &__gothic-bbb-pr6n {
    font-family: Gothic Medium BBB, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  &__futo-go-b101-pr6n {
    font-family: a-otf-futo-go-b101-pr6n, sans-serif;
    font-style: normal;
    font-weight: 500;
  }

  &--ten-mincho {
    font-family: ten-mincho, serif;
    font-style: normal;
    font-weight: 400;
  }

  &--eb-garamond {
    font-family: eb-garamond, serif;
    font-style: normal;
    font-weight: 400;
  }
}